import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { ReactSVG } from 'react-svg';
import { GoogleLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';
import AppleSignin from 'react-apple-signin-auth';
import logo from '../assets/logo.svg';
import handleSession from '../helpers/handleSession';

const redirect =
  process.env.NODE_ENV === 'production' ? 'https://app.heysabio.com' : 'https://aec9-81-129-53-12.ngrok-free.app';

const Auth = () => {
  useEffect(() => {
    const run = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get('code');

      if (code) {
        Cookies.set('affiliate', code);
      }

      const user = await handleSession({ credentialResponse: null, id_token: null });

      if (user) {
        if (user.onboardingData && user.subscriptionStatus === 'SUBSCRIBED') {
          window.location.href = '/home';
          return;
        }

        if (user.onboardingData && user.subscriptionStatus !== 'SUBSCRIBED') {
          if (user.onboardingData.goalByDate && user.onboardingData.restDays) {
            window.location.href = '/onboarding/free-trial';
            return;
          } else {
            window.location.href = '/onboarding/finalise';
            return;
          }
        }

        if (!user.onboardingData) {
          window.location.href = '/onboarding';
          return;
        }
      }
    };

    run();
  }, []);

  const handleGoogleLogin = async (credentialResponse) => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');

    if (code) {
      Cookies.set('affiliate', code);
    }

    const user = await handleSession({ credentialResponse });

    if (user) {
      if (user.onboardingData && user.subscriptionStatus === 'SUBSCRIBED') {
        window.location.href = '/home';
        return;
      }
      if (user.onboardingData && user.subscriptionStatus !== 'SUBSCRIBED') {
        if (user.onboardingData.goalByDate && user.onboardingData.restDays) {
          window.location.href = '/onboarding/free-trial';
          return;
        } else {
          window.location.href = '/onboarding/finalise';
          return;
        }
      }

      if (!user.onboardingData) {
        window.location.href = '/onboarding';
        return;
      }
    }
  };

  const handleAppleLogin = async (id_token) => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');

    if (code) {
      Cookies.set('affiliate', code);
    }

    const user = await handleSession({ id_token, credentialResponse: null });

    if (user) {
      if (user.onboardingData && user.subscriptionStatus === 'SUBSCRIBED') {
        window.location.href = '/home';
        return;
      }
      if (user.onboardingData && user.subscriptionStatus !== 'SUBSCRIBED') {
        if (user.onboardingData.goalByDate && user.onboardingData.restDays) {
          window.location.href = '/onboarding/free-trial';
          return;
        } else {
          window.location.href = '/onboarding/finalise';
          return;
        }
      }

      if (!user.onboardingData) {
        window.location.href = '/onboarding';
        return;
      }
    }
  };

  return (
    <Box
      sx={{
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ReactSVG src={logo} />
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 5, mb: 2 }}>
        <Typography variant="h2" sx={{ color: '#272620' }}>
          Welcome to
        </Typography>
        <Typography variant="h2" sx={{ color: '#EE6E12', ml: 0.7 }}>
          Sabio
        </Typography>
      </Box>
      <Box sx={{ width: { lg: '60%', md: '70%', sm: '80%', xs: '90%' }, textAlign: 'center', mb: 5 }}>
        <Typography sx={{ color: '#272620', lineHeight: 1.7 }}>
          Making intelligent fitness coaching accessible to all, let's get started!
        </Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        <AppleSignin
          authOptions={{
            clientId: 'com.heysabio.sabioweb',
            scope: 'email name',
            redirectURI: redirect,
            nonce: 'nonce',
            usePopup: true,
          }}
          onSuccess={({ authorization }) => {
            handleAppleLogin(authorization.id_token);
          }}
          onError={(error) => {
            console.log(error);
          }}
        />
      </Box>

      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        onSuccess={(credentialResponse) => {
          handleGoogleLogin(credentialResponse);
        }}
        onError={() => {
          console.log('Login Failed');
        }}
      />
    </Box>
  );
};

export default Auth;
