import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

const SetCompletionDate = ({ completionDate, setCompletionDate, error }) => {
  return (
    <Box
      sx={{
        width: '100%',
        mt: 5,
        mb: 4,
      }}
    >
      <Typography sx={{ color: '#272620', lineHeight: 1.7, mb: 3, fontWeight: 300 }}>
        Please select a date no less than a month away and no greater than a year away.
      </Typography>
      <TextField
        autoComplete="off"
        autoCorrect="off"
        placeholder="DD/MM/YYYY"
        error={error}
        value={completionDate}
        onChange={(e) => setCompletionDate(e.target.value)}
      />
    </Box>
  );
};

export default SetCompletionDate;
