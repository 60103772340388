import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import call from '../../helpers/call';

const ConnectStrava = ({ user, onNext }) => {
  let timeoutId;

  const [connected, setConnected] = useState(user?.onboardingData?.hasMadeConnection || false);

  const _captureResponse = async () => {
    try {
      // Retrieve the connections from the backend
      const response = await call('GET', `connect/list/${user?.id}`);

      if (response.length === 0) {
        // If the user hasn't connected their watch, run the function again in 2 seconds
        timeoutId = setTimeout(_captureResponse, 2000);
      } else {
        // If the user has connected their watch, close the safari view

        setConnected(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleConnect = async () => {
    try {
      const url = await call('GET', `connect/getUrl/strava/${user?.id}`);
      window.open(url, '_blank');

      _captureResponse();

      // Cleanup function to clear the timeout when the component unmounts or before the useEffect runs again
      return () => {
        if (timeoutId !== null) {
          clearTimeout(timeoutId);
        }
      };
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        mt: 5,
        mb: 4,
      }}
    >
      <Typography sx={{ color: '#272620', lineHeight: 1.7, mb: 3, fontWeight: 300 }}>
        Connecting Strava improves Sabio's ability to personalise your plan
      </Typography>
      <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            mr: 1,
            bgcolor: '#EE6E12',
            p: 1.5,
            borderRadius: 2,
            width: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            '&:hover': {
              bgcolor: '#EE6E1295',
            },
          }}
          onClick={handleConnect}
        >
          {connected && <CheckCircleOutline sx={{ color: '#fff', mr: 0.5 }} />}
          <Typography sx={{ color: '#fff' }}>{connected ? 'Connected' : 'Connect'}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ConnectStrava;
