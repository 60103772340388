import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import { ReactSVG } from 'react-svg';
import logo from '../../assets/logo.svg';
import yoga from '../../assets/yoga.svg';

const WaitingScreen = ({ progress, message }) => {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: { lg: '60%', md: '80%', xs: '95%' },
      }}
    >
      <ReactSVG src={logo} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 5,
          mb: 2,
          textAlign: 'center',
        }}
      >
        <Typography variant="h2" sx={{ color: '#272620' }}>
          Building your initial training plan
        </Typography>
        <Typography sx={{ mt: 1, mb: 5, color: '#27262090' }}>{message}</Typography>
        <LinearProgress variant="determinate" value={progress} />
        <ReactSVG src={yoga} />
      </Box>
    </Box>
  );
};

export default WaitingScreen;
