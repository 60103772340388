import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import handleSession from '../helpers/handleSession';
import { Button, Tooltip, Typography } from '@mui/material';
import logo from '../assets/logo.svg';
import { ReactSVG } from 'react-svg';

const Onboarding = () => {
  const [user, setUser] = useState();

  useEffect(() => {
    const run = async () => {
      const response = await handleSession({ id_token: null, credentialResponse: null });

      if (!response) {
        window.location.href = '/';
      }

      setUser(response);
    };

    run();
  }, []);

  const handleQuickstart = () => (window.location.href = '/onboarding/quickstart');
  const handleChat = () => (window.location.href = '/onboarding/chat');

  return (
    <Box
      sx={{
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ReactSVG src={logo} />
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 5, mb: 2 }}>
        <Typography variant="h2" sx={{ color: '#272620' }}>
          {`Nice to meet you, ${user?.name.split(' ')[0]}!`}
        </Typography>
      </Box>
      <Box sx={{ width: { lg: '40%', md: '50%', sm: '70%', xs: '95%' }, textAlign: 'center', mb: 5 }}>
        <Typography sx={{ color: '#272620', lineHeight: 1.7 }}>
          I'm <b>Sabio</b>, I've been built to help you achieve your fitness goals, no matter what they are. I'd like us
          to get to know eachother so I can truly personalise your training to fit you, your lifestyle and whatever
          limitations you have.
        </Typography>
      </Box>
      <Box>
        <Tooltip title="Higher level of detail (5 mins)">
          <Button onClick={handleChat} variant="contained" sx={{ color: '#fff', mr: 1 }}>
            Chat with Sabio
          </Button>
        </Tooltip>
        <Tooltip title="Quickstart (2 mins)">
          <Button onClick={handleQuickstart} variant="outlined" sx={{ color: '#EE6E12', ml: 1 }}>
            Quickstart
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default Onboarding;
