import React, { useEffect, useRef } from 'react';
import { Box, styled, keyframes } from '@mui/material';

const bounce = keyframes`
  0% { transform: scale(0.5); opacity: 0.5; }
  50% { transform: scale(1); opacity: 1; }
  100% { transform: scale(0.5); opacity: 0.5; }
`;

const Dot = styled('div')(({ theme }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundColor: '#00000090',
  margin: '0 3px',
  animation: `${bounce} 1.4s infinite ease-in-out both`,
}));

const DotsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const TypingIndicator = () => {
  const dots = [useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    const delays = ['0s', '0.2s', '0.4s'];
    dots.forEach((dot, index) => {
      dot.current.style.animationDelay = delays[index];
    });
  }, []);

  return (
    <DotsContainer>
      {dots.map((_, index) => (
        <Dot key={index} ref={dots[index]} />
      ))}
    </DotsContainer>
  );
};

export default TypingIndicator;
