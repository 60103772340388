import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import logo from '../assets/logo.svg';
import moment from 'moment';
import call from '../helpers/call';
import handleSession from '../helpers/handleSession';
import SelectRestDays from '../components/Onboarding/SelectRestDays';
import SetCompletionDate from '../components/Onboarding/SetCompletionDate';
import ConnectStrava from '../components/Onboarding/ConnectStrava';
import ConnectAccount from '../components/Onboarding/ConnectAccount';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';

const steps = ['When would you like to rest?', 'Set a completion date', 'iPhone App', 'Connect Strava'];

const OnboardingFinalise = () => {
  const [user, setUser] = useState();
  const [canNext, setCanNext] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [completionDate, setCompletionDate] = useState('');

  const [activeStep, setActiveStep] = React.useState(0);

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  const [smallScreen, setSmallScreen] = React.useState(screenWidth < 600);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  React.useEffect(() => {
    if (screenWidth < 900) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, [screenWidth]);

  // Sets the user var and redirects to auth if not authed
  useEffect(() => {
    const run = async () => {
      const response = await handleSession({ id_token: null, credentialResponse: null });

      if (!response) {
        window.location.href = '/';
      }

      setUser(response);

      if (response.onboardingData) {
        if (response.onboardingData.restDays && response.onboardingData.goalByDate) {
          window.location.href = '/onboarding/free-trial';
        }
      }
    };

    run();
  }, []);

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      // Last step so let's save all the info and move them onto the payment screen
      try {
        await call('POST', 'users/update', {
          userId: user.id,
          data: {
            onboardingData: {
              ...user.onboardingData,
              goalByDate: moment(completionDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
              restDays: selectedDays.map((d) => d.toLowerCase()),
            },
            onboarded: true,
          },
        });

        window.location.href = '/onboarding/free-trial';
      } catch (error) {}
    }
    setCanNext(false);

    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleSelect = (location) => {
    if (selectedDays.includes(location)) {
      setSelectedDays(selectedDays.filter((l) => l !== location));
    } else {
      setSelectedDays([...selectedDays, location]);
    }
  };

  useEffect(() => {
    if (activeStep === 0) {
      setCanNext(true);
    }
    if (activeStep === 1) {
      const selectedDate = moment(completionDate, 'DD/MM/YYYY');
      const isValidFormat = selectedDate.isValid();

      const oneMonthFromNow = moment().add(1, 'months');
      const oneYearFromNow = moment().add(1, 'years');

      if (isValidFormat && selectedDate.isAfter(oneMonthFromNow) && selectedDate.isBefore(oneYearFromNow)) {
        setCanNext(true);
      } else {
        setCanNext(false);
      }
    }

    if (activeStep === 2) {
      setCanNext(true);
    }
    if (activeStep === 3) {
      setCanNext(true);
    }
  }, [selectedDays, completionDate, activeStep]);

  return (
    <Box
      sx={{
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        p: 2,
      }}
    >
      <ReactSVG src={logo} />
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 5, mb: 2 }}>
        <Typography variant="h2" sx={{ color: '#272620' }}>
          Let's finalise your plan
        </Typography>
      </Box>
      <Box sx={{ width: { xs: '100%', md: '80%', lg: '60%' }, mt: 5 }}>
        <Stepper nonLinear activeStep={activeStep} orientation={smallScreen ? 'vertical' : 'horizontal'}>
          {steps.map((label, index) => (
            <Step key={label} completed={activeStep > index}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              {activeStep === 0 && <SelectRestDays handleSelect={handleSelect} selectedDays={selectedDays} />}
              {activeStep === 1 && (
                <SetCompletionDate
                  completionDate={completionDate}
                  setCompletionDate={setCompletionDate}
                  error={false}
                />
              )}
              {activeStep === 2 && <ConnectAccount user={user} setUser={setUser} />}
              {activeStep === 3 && <ConnectStrava user={user} setUser={setUser} />}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button disabled={!canNext} variant="contained" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </React.Fragment>
        </div>
      </Box>
    </Box>
  );
};

export default OnboardingFinalise;
