import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';

import theme from './theme.js';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Auth from './screens/Auth.js';
import Onboarding from './screens/Onboarding.js';
import OnboardingChat from './screens/OnboardingChat.js';
import OnboardingQuickstart from './screens/OnboardingQuickstart.js';
import OnboardingFinalise from './screens/OnboardingFinalise';
import StartTrial from './screens/StartTrial.js';

const Layout = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Auth />,
      },
      {
        path: 'onboarding',
        element: <Layout />,
        children: [
          {
            index: true,
            element: <Onboarding />,
          },
          {
            path: 'chat',
            element: <OnboardingChat />,
          },
          {
            path: 'quickstart',
            element: <OnboardingQuickstart />,
          },
          {
            path: 'finalise',
            element: <OnboardingFinalise />,
          },
          {
            path: 'free-trial',
            element: <StartTrial />,
          },
        ],
      },
    ],
  },
]);
const App = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
};

ReactDOM.createRoot(document.getElementById('root')).render(<App />);
