import axios from 'axios';

const apiBase = process.env.NODE_ENV === 'production' ? 'https://api.heysabio.com' : 'http://localhost:7074';

const call = async (method, path, data) => {
  try {
    const url = `${apiBase}/${path}`;
    const response = await axios({ method, url, data });

    return response.data;
  } catch (error) {
    console.log(error.message);
  }
};

export default call;
