import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import handleSession from '../helpers/handleSession';
import * as openai from '../helpers/openai';
import call from '../helpers/call';
import UserMessage from '../components/Chat/UserMessage';
import AssistantMessage from '../components/Chat/AssistantMessage';
import TypingIndicator from '../components/Chat/TypingAnimation';
import ChatHead from '../components/Chat/Head';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';

import styled from 'styled-components';

const AnimationContainer = styled.div`
  background-color: #eaddaf;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  margin-right: 45px;
  width: 60px;
`;

const OnboardingChat = () => {
  const [loading, setLoading] = useState(false);
  const [toolOutputs, setToolOutputs] = useState([]);
  const [shouldCompleteTool, setShouldCompleteTool] = useState(false);
  const [canSend, setCanSend] = useState(false);
  const [userMessage, setUserMessage] = useState('');
  const [runId, setRunId] = useState();

  const messageBoxRef = React.createRef();

  // Maybe use this to show a button if the app doesn't auto redirect?
  const [showNext, setShowNext] = useState(false);

  const [requiresResponse, setRequiresResponse] = useState(false);
  const [responsePending, setResponsePending] = useState(false);

  const [assistant, setAssistant] = useState();
  const [thread, setThread] = useState();
  const [messages, setMessages] = useState([]);

  const [user, setUser] = useState();

  useEffect(() => {
    const latestMessage = messages[messages.length - 1];

    // If the most recent message is from the user trigger an AI response
    if (latestMessage?.role === 'user') setRequiresResponse(true);
  }, [messages]);

  useEffect(() => {
    const run = async () => {
      const response = await handleSession({ id_token: null, credentialResponse: null });

      if (!response) {
        window.location.href = '/';
      }

      setUser(response);

      let _assistant;
      let _thread;
      let _messages;

      _assistant = await setupAssistant();
      _thread = await setupThread({ user: response });
      _messages = await setupMessages(_thread);

      setAssistant(_assistant);
      setThread(_thread);
      setMessages(_messages);

      // Get the most recent message
      const latestMessage = messages[messages.length - 1];

      // If the most recent message is from the user trigger an AI response
      if (latestMessage?.role === 'user') setRequiresResponse(true);
      if (latestMessage?.role === 'assistant') setCanSend(true);
    };

    run();
  }, []);

  const handleError = ({ error }) => {
    console.log(error.message);
  };

  const setupAssistant = async () => {
    if (assistant) return assistant;
    const { response, error } = await openai.retrieveAssistant('onboarding');

    if (response) return response;
    if (error) handleError({ error });
  };

  const setupThread = async ({ user }) => {
    if (thread) return thread;
    let threadId = user?.threadId;

    if (threadId) {
      const { response, error } = await openai.retrieveThread(threadId, user.id);
      if (response) return response;
      if (error) handleError({ error });
    } else {
      const { response, error } = await openai.createThread('onboarding', user.id);
      if (response) return response;
      if (error) handleError({ error });
    }
  };

  const setupMessages = async (thread) => {
    if (!thread) return [];
    const { response, error } = await openai.retrieveMessages(thread.id);

    if (response) return response;
    if (error) handleError({ error });
  };

  // Handles initialising the response from the AI to a new user message
  useEffect(() => {
    const _run = async () => {
      // If no response is required, then don't run
      if (!requiresResponse) return;

      // Set loading to true to show the loading indicator
      setLoading(true);

      // Initialise a response from the AI
      const { response, error } = await openai.run(thread.id, assistant.id, user?.id);

      if (error) handleError({ error });

      if (response) {
        // Save the id of the response
        setRunId(response.id);

        // Tell the component that a response is no longer required
        setRequiresResponse(false);

        // Tell the component that a response is pending
        setResponsePending(true);
      }
    };

    _run();
  }, [requiresResponse]);

  // Handles capturing the response and adding it to the message thread.
  useEffect(() => {
    let timeoutId = null;

    const _captureResponse = async () => {
      if (!responsePending) return;

      // Retrieve the response from the AI
      const { response, error } = await openai.retrieveRun(thread.id, runId, user?.id);

      if (error) handleError({ error });
      if (!response) return;

      console.log('Retrieved response, status is:' + response.status);

      if (response.status === 'in_progress' || response.status === 'queued') {
        // If the response isn't ready yet, run the function again in 2 seconds
        timeoutId = setTimeout(_captureResponse, 2000);
      } else if (response.status === 'completed') {
        // Get the new messages from the message thread and save them
        const { response, error } = await openai.retrieveMessages(thread.id, user?.id);

        if (error) handleError({ error });

        // Set loading to false to remove the loading indicator
        setLoading(false);

        // Update the state with the new messages
        setMessages(response);

        // Scroll to the bottom of the chat so the user can see the new message

        // Set canSend true to enable the user to send a new message
        setCanSend(true);

        // Tell the component that a response is no longer pending
        setResponsePending(false);
      } else if (response.status === 'requires_action') {
        // Extract the function data from the response
        const calls = openai.extractFunctionData(response, user?.id);

        for (let i = 0; i < calls.length; i++) {
          const { args, name, id } = calls[i];

          if (name === 'nextStep') {
            try {
              await call('POST', `users/saveOnboardingData`, {
                data: args,
                id: user?.id,
                threadId: thread.id,
              });

              setToolOutputs([
                ...toolOutputs,
                { id, response: 'User has completed the onboarding, wish them farewell for now.' },
              ]);

              // Update the state to move the user into the app
              setTimeout(() => {
                setShowNext(true);

                // Navigate to finalise
                window.location.href = '/onboarding/finalise';
              }, 1000);
            } catch (error) {
              setToolOutputs([
                ...toolOutputs,
                {
                  id,
                  response:
                    "Error completing onboarding, you'll need to apologise to the user and ask them if they'd like you to try again.",
                },
              ]);
            }
          }
        }

        // Trigger the tool completion
        setShouldCompleteTool(true);

        // Tell the component that a response is no longer pending
        setResponsePending(false);
      }
    };

    _captureResponse();

    // Cleanup function to clear the timeout when the component unmounts or before the useEffect runs again
    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, [responsePending]);

  // Handles completing the tool
  useEffect(() => {
    const _completeTool = async () => {
      if (!shouldCompleteTool) return;

      let raw_body = toolOutputs.map((tool, index) => {
        return { tool_call_id: tool.id, output: tool.response };
      });

      // Replace any undefined outputs with an error string
      for (let i = 0; i < raw_body.length; i++) {
        if (raw_body[i].output === undefined) {
          raw_body[i].output = 'Error: No output';
        }
      }

      const body = JSON.stringify({ tool_outputs: raw_body });

      const { response, error } = await openai.submitToolResponse({
        thread_id: thread.id,
        run_id: runId,
        body,
        userId: user?.id,
      });

      if (error) {
        handleError({ error });
      } else {
        // Clear tool outputs
        setToolOutputs([]);

        // Tell the component that the tool no longer needs to be completed
        setShouldCompleteTool(false);

        // Tell the component a response is pending
        setResponsePending(true);
      }
    };

    _completeTool();
  }, [shouldCompleteTool]);

  // Handles sending user message to the assistant
  const handleSendUserMessage = async () => {
    if (!userMessage) return;
    const latestMessage = messages[messages.length - 1];
    if (latestMessage?.role === 'user') return;

    // Stop the user from sending a message while the AI is responding
    setCanSend(false);

    // Add the user message to the message thread
    await openai.addUserMessage(thread.id, userMessage, user?.id);

    // Clear the user message
    setUserMessage('');

    // Retrieve the messages from the message thread
    const { response, error } = await openai.retrieveMessages(thread.id, user?.id);

    if (error) handleError({ error });

    // Update the state with the new messages
    setMessages(response);

    // Tell the AI to respond to the user message
    setRequiresResponse(true);

    // Scroll to the bottom of the chat so the user can see the new message
  };

  useEffect(() => {
    setTimeout(() => {
      if (messageBoxRef.current) {
        const scrollHeight = messageBoxRef.current.scrollHeight;
        let scrollPosition = messageBoxRef.current.scrollTop;
        const scrollInterval = setInterval(() => {
          if (scrollPosition < scrollHeight) {
            scrollPosition += 50; // Adjust this value to change the speed of the animation
            messageBoxRef.current.scrollTop = scrollPosition;
          } else {
            clearInterval(scrollInterval);
          }
        }, 20); // Adjust this value to change the frequency of the animation
      }
    }, 1000);
  }, [messages]);

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 2,
      }}
    >
      <ChatHead />
      <Box
        sx={{
          height: '90%',
          width: { xs: '100%', md: '80%', lg: '60%' },
          pt: 3,
          mb: 3,
          overflowY: 'scroll',
        }}
        ref={messageBoxRef}
      >
        {messages.map((message, index) => {
          if (message?.role === 'assistant') {
            return <AssistantMessage key={index} message={message.content[0].text.value} />;
          } else {
            return (
              <Box key={index} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <UserMessage key={index} message={message.content[0].text.value} />
              </Box>
            );
          }
        })}
        {loading && (
          <AnimationContainer>
            <TypingIndicator />
          </AnimationContainer>
        )}
      </Box>
      <Box sx={{ height: '10%', width: { xs: '100%', md: '80%', lg: '60%' } }}>
        <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}>
          <IconButton onClick={() => (window.location.href = '/onboarding')} sx={{ p: '10px' }} aria-label="menu">
            <ArrowBackIcon />
          </IconButton>
          <InputBase
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSendUserMessage();
              }
            }}
            autoComplete="off"
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
            sx={{ ml: 1, flex: 1 }}
            placeholder="Start typing..."
            inputProps={{ 'aria-label': 'chat with sabio' }}
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton onClick={handleSendUserMessage} color="primary" sx={{ p: '10px' }} aria-label="directions">
            <SendIcon />
          </IconButton>
        </Paper>
      </Box>
    </Box>
  );
};

export default OnboardingChat;
