import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import moment from 'moment';
import cycling from '../../assets/cycling.svg';
import running from '../../assets/running.svg';
import swim from '../../assets/swim.svg';
import rest from '../../assets/rest.svg';
import brick from '../../assets/brick.svg';
import custom from '../../assets/custom.svg';
import strength from '../../assets/strength.svg';
import stretch from '../../assets/strength.svg';
import { ReactSVG } from 'react-svg';

const LOGO_MAP = {
  run: running,
  stretch: stretch,
  strength: strength,
  rest: rest,
  ride: cycling,
  brick: brick,
  swim: swim,
  default: custom,
};

const Activity = ({ item }) => {
  return (
    <Grid xs={12} md={5.8} lg={3.8} sx={{ bgcolor: '#fff', height: 200, borderRadius: 4, p: 2 }} item>
      <Box id="top" sx={{ display: 'flex', alignItems: 'center', height: 40 }}>
        <ReactSVG src={LOGO_MAP[item.type] || LOGO_MAP['default']} />
        <Typography sx={{ ml: 1, mb: 0.5, color: '#272620', fontWeight: '500' }}>{item.title}</Typography>
      </Box>
      <Box id="mid" sx={{ display: 'flex', flex: 1, mt: 1.5, height: 90 }}>
        <Typography
          numberOfLines={2}
          sx={{
            ml: 0.5,
            fontWeight: 300,
            color: '#272620',
          }}
        >
          {item.guidance}
        </Typography>
      </Box>
      <Box
        id="bottom"
        sx={{ display: 'flex', alignItems: 'flex-start', height: 40, justifyContent: 'flex-end', mr: 1 }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography sx={{ color: '#272620', fontWeight: '500', mr: 0.5 }}>
            {moment(item.date, 'YYYY-MM-DD').format('Do')}
          </Typography>
          <Typography sx={{ color: '#272620', fontWeight: '500' }}>
            {moment(item.date, 'YYYY-MM-DD').format('MMM')}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default Activity;
