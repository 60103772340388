import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import handleSession from '../helpers/handleSession';
import WaitingScreen from '../components/Onboarding/WaitingScreen';
import FreeTrial from '../components/Onboarding/FreeTrial';
import { CircularProgress } from '@mui/material';
import call from '../helpers/call';

const StartTrial = () => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  const [progress, setProgress] = React.useState(0);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const run = async () => {
      if (user) {
        if (message === '') {
          const _message = await call('GET', `users/waitingScreenMessage/${user.id}`);
          setMessage(_message);
        }
        setLoading(false);
      }
    };
    run();
  }, [user]);

  React.useEffect(() => {
    let interval = null;
    const checkUserStage = async () => {
      const _user = await handleSession({ credentialResponse: null, id_token: null });
      setUser(_user);
      setProgress(_user.onboardingData.stage * 20);
      if (_user.onboardingData.stage === 5) {
        clearInterval(interval);
      }
    };
    interval = setInterval(checkUserStage, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: '#F2F3F5',
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : progress === 100 ? (
        <>
          <FreeTrial user={user} />
        </>
      ) : (
        <WaitingScreen progress={progress} message={message} />
      )}
    </Box>
  );
};

export default StartTrial;
