import { Box, Typography } from '@mui/material';
import React from 'react';
import { ReactSVG } from 'react-svg';
import logo from '../../assets/logoSmall.svg';

const ChatHead = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: 80,
        width: { xs: '100%', md: '80%', lg: '60%' },
      }}
    >
      <Box
        sx={{
          width: 70,
          height: 70,
          borderRadius: 35,
          bgcolor: '#f8f8f8',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ReactSVG src={logo} style={{ marginTop: 1 }} />
      </Box>
      <Box sx={{ ml: 1.5 }}>
        <Typography variant="h2" sx={{ color: '#272620', fontSize: 30 }}>
          Sabio
        </Typography>
        <Typography sx={{ color: '#272620', fontWeight: 300, fontSize: 15 }}>Let's get to know eachother!</Typography>
      </Box>
    </Box>
  );
};

export default ChatHead;
