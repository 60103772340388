import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AppleSignin from 'react-apple-signin-auth';
import call from '../../helpers/call';
import { Check, CheckCircle } from '@mui/icons-material';

const redirect =
  process.env.NODE_ENV === 'production' ? 'https://app.heysabio.com' : 'https://aec9-81-129-53-12.ngrok-free.app';

const ConnectAccount = ({ user, setUser }) => {
  const [error, setError] = useState(false);
  const [connected, setConnected] = useState(user?.associatedAppleIdentityToken);

  useEffect(() => {
    setConnected(user?.associatedAppleIdentityToken);
  }, [user]);

  const handleAppleLogin = async (id_token) => {
    try {
      const _user = await call('POST', 'users/associateAppleId', { userId: user.id, id_token });
      if (_user?.associatedAppleIdentityToken) {
        setUser(_user);
      }
    } catch (error) {
      setError(true);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        mt: 5,
        mb: 4,
      }}
    >
      <Typography sx={{ color: '#272620', lineHeight: 1.7, mb: 3, fontWeight: 300 }}>
        {connected
          ? "You've connected your account!"
          : 'To use this account on our IOS app, connect your Apple account below'}
      </Typography>

      <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {connected && <Check sx={{ mr: 1, fontSize: 40, color: '#EE6E12' }} />}
        <AppleSignin
          authOptions={{
            clientId: 'com.heysabio.sabioweb',
            scope: 'email name',
            redirectURI: redirect,
            nonce: 'nonce',
            usePopup: true,
          }}
          onSuccess={({ authorization }) => {
            handleAppleLogin(authorization.id_token);
          }}
          onError={(error) => {
            console.log(error);
          }}
        />
      </Box>
    </Box>
  );
};

export default ConnectAccount;
