import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { ReactSVG } from 'react-svg';
import logo from '../../assets/logo.svg';
import call from '../../helpers/call';
import Activity from '../../components/Onboarding/Activity';
import moment from 'moment';

const FreeTrial = ({ user }) => {
  const [loading, setLoading] = useState(true);
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    const run = async () => {
      const _activities = await call('GET', `users/retrievePlan/${user.id}`);
      const sortedActivities = _activities.plan.data
        .filter((a) => a.type !== 'unplanned')
        .sort((a, b) => new Date(a.date) - new Date(b.date));

      setActivities(sortedActivities.slice(0, 7));
    };

    run();
  }, []);

  // type, date, title, guidance

  return (
    <Box
      sx={{
        flex: 1,
        pt: 5,
        pb: 5,
        overflowY: 'scroll',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: { xs: '95%', md: '90%', lg: '80%' },
          p: 3,
        }}
      >
        <ReactSVG src={logo} />
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 5, mb: 2 }}>
          <Typography variant="h2" sx={{ color: '#272620' }}>
            It's time to achieve your goal,
          </Typography>
          <Typography variant="h2" sx={{ color: '#EE6E12', ml: 0.7 }}>
            {user.name.split(' ')[0]}
          </Typography>
        </Box>
        <Box sx={{ width: '100%', textAlign: 'center', mb: 5 }}>
          <Typography sx={{ color: '#272620', lineHeight: 1.7 }}>
            Before you start your <b>free trial</b>, here's what your first week will look like
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <Grid container rowGap={2} columnGap={2} sx={{ justifyContent: 'center' }}>
            {activities.map((activity) => {
              return <Activity item={activity} />;
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default FreeTrial;
