import Cookies from 'js-cookie';
import axios from 'axios';
import call from './call';

const base_url = process.env.NODE_ENV === 'production' ? 'https://api.heysabio.com' : 'http://localhost:7074';

const handleSession = async ({ credentialResponse, id_token }) => {
  const route = '/' + window.location.href.split('/')[window.location.href.split('/').length - 1];

  const token_type = credentialResponse ? 'google' : id_token ? 'apple' : Cookies.get('token_type');

  // Get token from either credResponse or cookie
  const token = credentialResponse ? credentialResponse.credential : id_token ? id_token : Cookies.get('token');
  const affiliate = Cookies.get('affiliate');

  // If it doesn't exist, return
  if (!token) {
    if (route !== '/') window.location.href = '/';
    return;
  }

  if (token_type === 'google') {
    // Send to Google to validate
    const response = await fetch('https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=' + token);

    // Not valid, so remove cookie and return
    if (!response.ok) {
      Cookies.remove('token');
      Cookies.remove('token_type');
      if (route !== '/') window.location.href = '/';
      return;
    }

    Cookies.set('token', token);
    Cookies.set('token_type', 'google');

    // Create or Retrieve User
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const data = await response.json();

    try {
      const user = await axios.post(`${base_url}/users/googleAuth`, { ...data, timezone: tz, affiliate });

      // Something went wrong, show Error
      if (!user.data) {
        if (route !== '/') window.location.href = '/';
        return;
      }

      return user.data;
    } catch (error) {
      console.log(error);
      return;
    }
  }

  if (token_type === 'apple') {
    try {
      const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const user = await call('POST', 'users/appleAuth', { id_token: token, timezone: tz });

      if (!user) {
        Cookies.remove('token');
        Cookies.remove('token_type');

        if (route !== '/') window.location.href = '/';
        return;
      }

      Cookies.set('token', token);
      Cookies.set('token_type', 'apple');

      return user;
    } catch (error) {
      console.log(error);
      return;
    }
  }
};

export default handleSession;
