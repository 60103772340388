import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const item_styles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #27262020',
  borderRadius: 2,
  p: 2,
  cursor: 'pointer',
  '&:hover': {
    bgcolor: '#27262010',
  },
};

const item_selected_styles = { ...item_styles, bgcolor: '#27262010' };

const SelectRestDays = ({ handleSelect, selectedDays }) => {
  return (
    <Box
      sx={{
        width: '100%',
        mt: 5,
        mb: 4,
      }}
    >
      <Grid container rowGap={1} columnGap={1}>
        {days.map((day) => {
          const onClick = () => handleSelect(day);

          return (
            <Grid
              key={day}
              onClick={onClick}
              xs={4}
              md={2}
              item
              sx={selectedDays.includes(day) ? item_selected_styles : item_styles}
            >
              <Typography sx={[{ fontWeight: 300 }]}>{day}</Typography>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default SelectRestDays;
