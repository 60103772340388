import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: '#EADDAF',
  borderRadius: '10px',
  padding: theme.spacing(1.125, 1.875), // Converted from px to theme spacing
  marginBottom: '20px',
  marginRight: '45px',
  width: 'fit-content',
}));

const StyledText = styled(Typography)(({ theme }) => ({
  color: '#272620',
}));

const AssistantMessage = ({ message }) => {
  return (
    <Container>
      <StyledText>{message}</StyledText>
    </Container>
  );
};

export default AssistantMessage;
